import React, { useEffect, useState } from "react";
import {useDispatch, useSelector } from "react-redux";
import {push} from "connected-react-router";
import moment from "moment";
import {
  AssecoDataTable, LoadingComponent, NoItemsComponent,
  FDatePicker, FSelect, FTextField, FAutocomplete,
} from "asseco-commons";
import { properties } from "../../config/properties";
import { translate } from "../../util/lang/translate-wrapper";
// import PeriodicReportsAddEdit from "./PeriodicReportsAddEdit";
import AlertDialog from "../../util/alert/AlertDialog";
import { Field, Formik, Form } from "formik";
import AddIcon from "@material-ui/icons/Add";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import {
    Grid, Container, Card, CardActions, CardHeader, CardContent,
    Tooltip, Button, Typography, Paper, IconButton, CircularProgress, LinearProgress,
    makeStyles
} from "@material-ui/core";
import * as Yup from "yup";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SearchIcon from "@material-ui/icons/Search";
import {renderDate} from "../../util/helper/generalHelpers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TodayIcon from "@material-ui/icons/Today";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { withNamespaces } from 'react-i18next';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNotesMedical, faSyringe } from '@fortawesome/free-solid-svg-icons'

function MedicinskiSredstvaComponent(props) {
    const useStyles = makeStyles(() => ({
      header: {
          textAlign: 'center',
          color: '#741f6d',
          fontWeight: 'bold',
          textTransform: 'uppercase',
      },
      headerIcon: {
          fontSize: '30px',
          marginBottom: '-0.30rem',
      },
      borderComponent:{
        border:"2px solid #a32777",
        borderRadius:"10px"
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rerenderSifrarnik, setRerenderSifrarnik] = useState(true);
  const [tableData, setTableData] = useState(props.items);
  const [screenWidth, setScreenWidth] = useState(window.outerWidth-55+"px");

  useEffect(() => {
    return function cleanup() {
      rerender();
    };
  }, []);

  const rerender = () => {
    setRerenderSifrarnik(!rerenderSifrarnik);
    props.resetReducer();
  };

  useEffect(()=>{ 
    setTableData(undefined);
    setTimeout(()=>{
      setTableData(props.items);
    },1);   
  },[props.lng, props.items]);

  useEffect(()=>{
    props.fetchItems()
  },[])
  function closeDialog() {
    props.setSelectedItem(undefined);
  }
  
  let initialValues = {datumDo:null, datumOd:null};

  const validationSchema = Yup.object().shape({
   });

  return (
    <>
      <div key={rerenderSifrarnik} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            // isInitialValid={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
          
            }}
            render={(formikProps) => (
              <Form autoComplete="off" style={{padding:"20px"}}>
                <Card className={classes.borderComponent}>
                  <CardHeader
                      className={classes.header}
                      title={<>
                          <FontAwesomeIcon style={{marginRight:"10px"}} icon={faSyringe} size="md" /> {' '}
                          {translate("app.medicinskiSredstva.header")}
                      </>}
                  />
                
                </Card>
              </Form>
            )}
          />
        </Grid>
      </div>
      {/* {(props.loadingItems || props.processingNotifications) && <><LoadingComponent /><br/></>} */}
      {/* {props.items != undefined && props.items.length !== 0 && (
        <> */}
          <AssecoDataTable
            type={"devExtreme"}
            wordWrapEnabled={true}
            data={tableData}
            columnId={"id"}
            columns={[
              {
                name: "ime_medicinsko_pomagalo",
                label: translate("app.medicinskiSredstva.naziv"),
                cellProps: { align: "left", width: "250px", fontSize: "25px" },
              },
              {
                name: "komintent",
                label: translate("app.medicinskiSredstva.nositel_odobrenie"),
                cellProps: { align: "left" },
              },
              {
                name: "nacin_izdavanje",
                label: translate("app.medicinskiSredstva.nacin_izdavanje"),
                cellProps: { align: "left"},
              },
              {
                name: "datum_vaznost",
                label: translate("app.medicinskiSredstva.datumVaznost"),
                cellProps: { align: "left", width:"130px"},
                customCell: (value)=> renderDate(value),
              },
              {
                name: "osnovno_broj_resenie",
                label: translate("app.medicinskiSredstva.osnovno_broj_resenie"),
                cellProps: { align: "left", width:"120px"},
              },
              {
                name: "osnovno_datum_resenie",
                label: translate("app.medicinskiSredstva.osnovno_datum_resenie"),
                cellProps: { align: "left", width:"130px" },
                customCell: (value)=> renderDate(value),
              },
              
              {
                name: "naziv_klasa",
                label: translate("app.medicinskiSredstva.naziv_klasa"),
                cellProps: { align: "left", width:"110px" },
              },
              {
                name: "naziv_kategorija_namena",
                label: translate("app.medicinskiSredstva.naziv_kategorija_namena"),
                cellProps: { align: "left" },
              },
              {
                name: "naziv_kategorija",
                label: translate("app.medicinskiSredstva.naziv_kategorija"),
                cellProps: { align: "left" },
              },
            ]}
            filtering
            sorting
            paging
            hiddenColumns={[]}
            export
            columnResizing
            icons={{
              details: <OpenInBrowserIcon style={{color:"#912777", fontSize:"20px"}}/>
            }}
            tooltips={{
            }}
            onDetails={(item)=>{
                dispatch(push({
                  pathname: '/medicinski_sredstva/' + item.id,
                  state: { props: item }
                }))
            }}
            actionsColumn={{
              alignColumnInTable:"left",
              width:56
            }}
          />
        {/* </>
     )} */}
      {props.items !== undefined && props.items.length === 0 && (
        <Grid
          style={{ marginTop: "10px", textAlign: "center"  }}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Paper style={{ paddingBottom: 10 + "px", paddingTop: 10 + "px" }}>
              <LayersClearIcon />
              <Typography component="h5" variant="h6" color=" inherit" noWrap>
                {translate("app.medicinskiSredstva.noResult")}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
      {/* <medicinskiSredstvaAddEdit
        item={props.selectedItem}
        onClose={() => closeDialog()}
        onSave={(item) => {
          props.saveItem(item);
        }}
      /> */}
    </>
  );
}

export default withNamespaces()(MedicinskiSredstvaComponent);
