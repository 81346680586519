import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { AssecoDataTable } from "asseco-commons";
import { properties } from "../../config/properties";
import { translate } from "../../util/lang/translate-wrapper";
// import PeriodicReportsAddEdit from "./PeriodicReportsAddEdit";
import AlertDialog from "../../util/alert/AlertDialog";
import { Field, Formik, Form } from "formik";
import AddIcon from "@material-ui/icons/Add";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import {
  Grid,
  Box,
  Tooltip,
  Button,
  Typography,
  Paper,
  Dialog,
  TextField,
  makeStyles,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import * as Yup from "yup";
import { push, goBack } from "connected-react-router";
import { renderDateTextField } from "../../util/helper/generalHelpers";
import { withNamespaces } from "react-i18next";
import operations from "./duck/operations";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontSize: "10px",
  },
  tab: {
    border: "2px solid #df4c4c",
    color: "#df4c4c",
  },
  textField: {
    width: "95%",
  },
  inputProps: {
    paddingLeft: "0px !important",
    margin: "0px !important",
    height: "30px",
    fontSize: "14px",
    backgroundColor: "#fcfcfc",
  },
  inputPropsMultiline: {
    paddingLeft: "12px !important",
    paddingTop: "6px",
    fontSize: "14px",
    backgroundColor: "#fcfcfc",
  },
  head: {
    fontWeight: "bold",
  },
  gridText: {
    marginTop: "6px",
  },
}));

function LekoviDetails(props) {
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(undefined);
  const [value, setValue] = React.useState(0);
  const [proizvoditeli, setProizvoditeli] = useState(undefined);
  const [ispituvaci, setIspituvaci] = useState(undefined);
  const classes = useStyles();
  const dispatch = useDispatch();
  const propsKI = props.location.state.props;

  useEffect(() => {
    if (props.closeDeleteDialog === true) setOpenDeleteAlert(false);
  }, [props.closeDeleteDialog]);

  useEffect(() => {
    dispatch(operations.fetchProizvoditeliById(propsKI.id)).then((res) => {
      setProizvoditeli(res);
    });

    dispatch(operations.fetchIspituvaciById(propsKI.id)).then((res) => {
      setIspituvaci(res);
    });
  }, []);

  function handleClick(event) {
    dispatch(goBack());
  }

  function closeDialog() {
    props.setSelectedItem(undefined);
  }

  let initialValues = { datumDo: null, datumOd: null };

  const validationSchema = Yup.object().shape({});

  return (
    <>
      <div className={classes.root}>
        <TabPanel value={value} index={0}>
          <Breadcrumbs style={{ marginTop: "-20px", marginBottom: "20px" }}>
            <Link
              style={{ fontSize: "12px", cursor: "pointer" }}
              color="inherit"
              onClick={handleClick}
            >
              {translate("app.klinickiIspituvanja.klinickiIspituvanja")}
            </Link>
            <Typography style={{ fontSize: "18px", fontWeight:"bold" }} color="textPrimary">
              {propsKI.ime_lek}
            </Typography>
          </Breadcrumbs>

          <Grid
            container
            spacing={1}
            xs={12}
            style={{
              fontSize: "12px",
              border: "1px solid #902777",
              borderRadius: "15px",
              padding: "10px",
            }}
          >
            <Grid item xs={2} style={{ wordWrap: "break-word" }}>
              {translate("app.klinickiIspituvanja.brojOdobrenie")}
              :
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.osnovno_broj_resenie}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.datumOdobrenie")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={renderDateTextField(propsKI.osnovno_datum_resenie)}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.registarLekovi.brojUkinuvanje")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.broj_ukinuvanje}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.registarLekovi.datumUkinuvanje")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={renderDateTextField(propsKI.datum_ukinuvanje)}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.registarLekovi.datumVaznost")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={renderDateTextField(propsKI.datum_vaznost)}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            xs={12}
            style={{
              marginTop: "30px",
              fontSize: "12px",
              border: "1px solid #902777",
              borderRadius: "15px",
              padding: "10px",
            }}
          >
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.registarLekovi.podnositelBaranje")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.komintent}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.naslovIspituvanje")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.naslov}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputPropsMultiline,
                  readOnly: true,
                }}
                multiline
                rows={6}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.brojNaProtokol")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.broj_na_protokol}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.registarLekovi.imeLekLatinica")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.ime_lek}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.jacinaLek")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.jacina}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.farmacevtskaDoziranaForma")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.farmacevtska_forma}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.fazaNaIspituvanjeto")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.faza_na_ispituvanjeto}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.sponzorIspituvanje")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.sponzor_ispituvanje}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={2} className={classes.gridText}>
              {translate("app.klinickiIspituvanja.datumPocetok")}:
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                fullWidth
                value={propsKI.datumpocetok}
                variant="outlined"
                size="small"
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          {proizvoditeli && (
            <form
              item
              xs={12}
              style={{
                marginTop: "30px",
                paddingLeft: "2px",
                paddingTop: "2px",
                paddingRight: "2px",
                borderRadius: "7px",
                background:
                  "linear-gradient(to bottom, #902777, rgba(0, 0, 0, 0) 45%)",
              }}
            >
              <AssecoDataTable
                type={"devExtreme"}
                wordWrapEnabled={true}
                data={proizvoditeli}
                columnId={"id"}
                columns={[
                  {
                    name: "ime_proizvoditel",
                    label: translate("app.klinickiIspituvanja.proizvoditel"),
                    cellProps: { align: "left", width: "250px", },
                  },
                  {
                    name: "drzava_naziv",
                    label: translate("app.klinickiIspituvanja.drzava"),
                    cellProps: { align: "left" },
                  },
                  {
                    name: "grad_naziv",
                    label: translate("app.klinickiIspituvanja.grad"),
                    cellProps: { align: "left" },
                  },
                  {
                    name: "adresa_proizvoditel",
                    label: translate("app.klinickiIspituvanja.adresa"),
                    cellProps: { align: "left" },
                  },
                  {
                    name: "naziv_mesto",
                    label: translate("app.klinickiIspituvanja.nazivMesto"),
                    cellProps: { align: "left" },
                  },
                ]}
                filtering
                sorting
                columnResizing
              />
            </form>
          )}

          {ispituvaci && (
            <Grid
              item
              xs={12}
              style={{
                marginTop: "30px",
                paddingLeft: "2px",
                paddingTop: "2px",
                paddingRight: "2px",
                borderRadius: "7px",
                background:
                  "linear-gradient(to bottom, #902777, rgba(0, 0, 0, 0) 45%)",
              }}
            >
              <AssecoDataTable
                type={"devExtreme"}
                wordWrapEnabled={true}
                data={ispituvaci}
                columnId={"id"}
                columns={[
                  // {
                  //   name: "glaven_ispituvac",
                  //   label: translate("app.klinickiIspituvanja.glavenIspituvac"),
                  //   cellProps: { align: "left", },
                  // },
                  {
                    name: "ime_ispituvac",
                    label: translate("app.klinickiIspituvanja.naziv"),
                    cellProps: { align: "left", },
                  },
                  {
                    name: "drzava_naziv",
                    label: translate("app.klinickiIspituvanja.drzava"),
                    cellProps: { align: "left", },
                  },
                  {
                    name: "grad_naziv",
                    label: translate("app.klinickiIspituvanja.grad"),
                    cellProps: { align: "left", },
                  },
                  {
                    name: "adresa_ispituvac",
                    label: translate("app.klinickiIspituvanja.adresa"),
                    cellProps: { align: "left", },
                  },
                ]}
                filtering
                sorting
                columnResizing
              />
            </Grid>
          )}
        </TabPanel>
      </div>

      {props.items !== undefined && props.items.length === 0 && (
        <Grid
          style={{ marginTop: "10px", textAlign: "center" }}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Paper style={{ paddingBottom: 10 + "px", paddingTop: 10 + "px" }}>
              <LayersClearIcon />
              <Typography component="h5" variant="h6" color=" inherit" noWrap>
                {translate("app.registarLekovi.noResult")}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default withNamespaces()(LekoviDetails);
