import React, { useEffect, useState } from "react";
import {useDispatch, useSelector } from "react-redux";
import {push} from "connected-react-router";
import moment from "moment";
import {
  AssecoDataTable, LoadingComponent, NoItemsComponent,
  FDatePicker, FSelect, FTextField, FAutocomplete,
} from "asseco-commons";
import { properties } from "../../config/properties";
import { translate } from "../../util/lang/translate-wrapper";
// import PeriodicReportsAddEdit from "./PeriodicReportsAddEdit";
import AlertDialog from "../../util/alert/AlertDialog";
import { Field, Formik, Form } from "formik";
import AddIcon from "@material-ui/icons/Add";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import {
    Grid, Container, Card, CardActions, CardHeader, CardContent,
    Tooltip, Button, Typography, Paper, IconButton, CircularProgress, LinearProgress,
    makeStyles,
} from "@material-ui/core";
import * as Yup from "yup";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SearchIcon from "@material-ui/icons/Search";
import {renderDate} from "../../util/helper/generalHelpers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TodayIcon from "@material-ui/icons/Today";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import LekoviDetails from "./LekoviDetails";
import i18next from "../../util/lang/i18n";
import { withNamespaces } from 'react-i18next';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPills } from '@fortawesome/free-solid-svg-icons'

function RegistarLekoviComponent(props) {
  const useStyles = makeStyles(() => ({
    header: {
        textAlign: 'center',
        color: '#741f6d',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    headerIcon: {
        fontSize: '30px',
        marginBottom: '-0.30rem',
    },
    borderComponent:{
      border:"2px solid #a32777",
      borderRadius:"10px"
  },
}));

  const dispatch = useDispatch();
  const classes = useStyles();
  const [rerenderSifrarnik, setRerenderSifrarnik] = useState(true);
  const [dateCreatedBoundaries, setDateCreatedBoundaries] = useState(true);
  const [dateModifiedBoundaries, setDateModifiedBoundaries] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.outerWidth-55+"px");
  const [tableData, setTableData] = useState(props.items);

  const nacinIzdavanjeEnum = (value) =>{
    if(value===0)
      return <div style={{fontSize:"10px",}} >{translate("app.registarLekovi.PRESCRIPTION")}</div>
    else if(value===1)
      return <div style={{fontSize:"10px"}} >{translate("app.registarLekovi.HEALTHORGANIZATION")}</div>
    else if(value===2)
      return <div style={{fontSize:"10px"}} >{translate("app.registarLekovi.NOPRESCRIPTION")}</div>
    else if(value===3)
      return <div style={{fontSize:"10px"}} >{translate("app.registarLekovi.ALL")}</div>
    else return value;
  }

  useEffect(() => {
    return function cleanup() {
      rerender();
    };
  }, []);

  useEffect(()=>{ 
    setTableData(undefined);
    setTimeout(()=>{
      setTableData(props.items);
    },1);
    
  },[props.lng, props.items]);

  useEffect(()=>{
    props.fetchItems()
  },[])
  const rerender = () => {
    setRerenderSifrarnik(!rerenderSifrarnik);
    props.resetReducer();
  };

  useEffect(()=>{
    const handleTableResize = () => setScreenWidth(window.outerWidth-55+"px")
    window.addEventListener("resize", handleTableResize);

    return () => window.removeEventListener("resize", handleTableResize);
    
  },[]);

  let initialValues = {datumDo:null, datumOd:null};

  const validationSchema = Yup.object().shape({
   });


  return (
    <>
      <div key={rerenderSifrarnik} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            // isInitialValid={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
            }}
            render={(formikProps) => (
              <Form autoComplete="off" style={{padding:"20px"}}>
                <Card className={classes.borderComponent}>
                  <CardHeader
                      className={classes.header}
                      title={<>
                        <FontAwesomeIcon style={{marginRight:"10px"}} icon={faPills}  size="md" /> {' '}
                          {translate("app.registarLekovi.header")}
                      </>}
                  />
                
                </Card>
              </Form>
            )}
          />
        </Grid>
      </div>
      {/* {(props.loadingItems || props.processingNotifications) && <><LoadingComponent /><br/></>} */}
      {/* {props.items != undefined && props.items.length !== 0 && (
        <> */}
        
          <form autoComplete="off" style={{maxWidth: screenWidth, paddingLeft:"5px" }}>
          <AssecoDataTable
            type={"devExtreme"}
            wordWrapEnabled={true}
            data={tableData}
            columnId={"id"}
            columns={[
              {
                name: "ime_lek_latinica",
                label: translate("app.registarLekovi.imeLekLatinica"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "ime_lek_kirilica",
                label: translate("app.registarLekovi.imeLekKirilica"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "inn",
                label: translate("app.registarLekovi.genericnoIme"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "atc_kod",
                label: translate("app.registarLekovi.atcKod"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "jacina",
                label: translate("app.registarLekovi.jacina"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "pakuvanje",
                label: translate("app.registarLekovi.pakuvanje"),
                cellProps: { align: "left", width:"140px" },
              },
              {
                name: "farmcevtska_forma",
                label: translate("app.registarLekovi.farmacevtskaForma"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "sostav",
                label: translate("app.registarLekovi.sostav"),
                cellProps: { align: "left", width:"220px" },
              },
              {
                name: "nacin_izdavanje",
                label: translate("app.registarLekovi.nacinIzdavanje"),
                cellProps: { align: "left", width:"145px" },
                customCell: (value) => nacinIzdavanjeEnum(value),
              },
              {
                name: "osnovno_broj_resenie",
                label: translate("app.registarLekovi.brojOsnovnoResenie"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "osnovno_datum_resenie",
                label: translate("app.registarLekovi.datumOsnovnoResenie"),
                cellProps: { align: "left", width:"130px" },
                customCell: (value) => renderDate(value),
              },
              {
                name: "datum_vaznost",
                label: translate("app.registarLekovi.datumVaznost"),
                cellProps: { align: "left", width:"130px" },
                customCell: (value) => renderDate(value),
              },
              {
                name: "brojModificiranoResenie",
                label: translate("app.registarLekovi.brojModificiranoResenie"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "datumModificiranoResenie",
                label: translate("app.registarLekovi.datumModificiranoResenie"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "broj_prenos",
                label: translate("app.registarLekovi.brojPrenosArhivskiBroj"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "datum_prenos",
                label: translate("app.registarLekovi.datumPrenosDatumArhiva"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "broj_odobrenie_notifikacija",
                label: translate("app.registarLekovi.brojOdobrenieIzvestuvanje"),
                cellProps: { align: "left", width:"130px" },
              },
              {
                name: "datum_odobrenie_notifikacija",
                label: translate("app.registarLekovi.datumOdobrenieIzvestuvanje"),
                cellProps: { align: "left", width:"130px" },
              },
            ]}
            filtering
            sorting
            paging
            hiddenColumns={["datum_odobrenie_notifikacija","broj_odobrenie_notifikacija","datum_prenos","broj_prenos","datumModificiranoResenie",
                            "brojModificiranoResenie"]}
            export
            columnResizing
            icons={{
                details: <OpenInBrowserIcon style={{color:"#912777", fontSize:"20px"}}/>
              }}
            tooltips={{
            }}
            onDetails={(item)=>{
                dispatch(push({
                  pathname: '/lekovi/' + item.ime_lek_latinica,
                  state: { props: item }
                }))
            }}
          // onRowClick={(item)=>{
          //   dispatch(push({
          //     pathname: '/registar-lekovi/' + item.ime_lek_latinica,
          //     state: { props: item }
          //   }))
          // }}
          actionsColumn={{
            alignColumnInTable:"left",
            width:56
          }}
          />
          </form>
      
      {/* <registarLekoviAddEdit
        item={props.selectedItem}
        onClose={() => closeDialog()}
        onSave={(item) => {
          props.saveItem(item);
        }}
      /> */}
    </>
  );
}

export default withNamespaces()(RegistarLekoviComponent);
