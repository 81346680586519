import React, {useEffect} from "react";
import NoMatchComponent from "./error/no-match-component";
import { Switch, Route } from "react-router-dom";
import AuthorizedRoute from "./util/auth/AuthorizedRoute";
import PublicRoute from "./util/auth/PublicRoute";
import CallbackPage from "./util/auth/comps/CallbackPage";
import LogoutPage from "./util/auth/comps/LogoutPage";
import ProfilePage from "./util/auth/comps/ProfilePage";
import InactivityPage from "./util/auth/comps/InactivityPage";
import UnauthorizedPage from "./util/auth/comps/UnauthorizedPage";
import SilentRenew from "./util/auth/comps/silent-renew";
import Layout from "./mainLayout";
import ErrPage from "./error/error";
import Err404Page from "./error/error404";
import Home from "./Home";
import {useDispatch, useSelector} from "react-redux";
import operations from "./duck/operations";
import MedicinskiSredstvaContainer from "./domain/RegistarMedicinskiSredstva/MedicinskiSredstvaContainer";
import MedicinskiSredstvaDetails from "./domain/RegistarMedicinskiSredstva/MedicinskiSredstvaDetails";
import LekoviContainer from "./domain/RegistarLekovi/LekoviContainer";
import LekoviDetails from "./domain/RegistarLekovi/LekoviDetails";
import KlinickiIspituvanjaContainer from "./domain/RegistarKlinickiIspituvanja/KlinickiIspituvanjaContainer";
import KlinickiIspituvanjaDetails from "./domain/RegistarKlinickiIspituvanja/KlinickiIspituvanjaDetails";

export default function Routes() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.oidc.user);

  useEffect(() => {
    if(user !== null){
      dispatch(operations.getUserInfo(user.profile.user_name));
    }
  }, [user])

  return (
    <>
      <Switch>
        <PublicRoute exact path="/callback" component={CallbackPage} />
        <PublicRoute exact path="/silent_renew" component={SilentRenew} />
        <PublicRoute exact path="/err" component={ErrPage} />
        <PublicRoute exact path="/err404" component={Err404Page} />
        <PublicRoute exact path="/logout" component={LogoutPage} />
        <PublicRoute exact path="/profile" component={ProfilePage} />
        <Route exact path="/inactivity" component={InactivityPage} />
        <Route exact path="/Unauthorized" component={UnauthorizedPage} />
        <PublicRoute exact path="/" component={MedicinskiSredstvaContainer} layout={Layout} />
        <PublicRoute exact path={`/lekovi`} component={LekoviContainer} layout={Layout} />
        <PublicRoute exact path="/lekovi/:name" component={LekoviDetails} layout={Layout} />
        <PublicRoute exact path="/medicinski_sredstva" component={MedicinskiSredstvaContainer} layout={Layout} />
        <PublicRoute exact path="/medicinski_sredstva/:name" component={MedicinskiSredstvaDetails} layout={Layout} />
        <PublicRoute exact path="/klinicki_ispituvanja/" component={KlinickiIspituvanjaContainer} layout={Layout} />
        <PublicRoute exact path="/klinicki_ispituvanja/:name" component={KlinickiIspituvanjaDetails} layout={Layout} />
        <Route component={NoMatchComponent} />
      </Switch>
    </>
  );
}
