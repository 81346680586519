import { httprequest } from "asseco-commons";
import { properties } from "../config/properties";

export const fetchItms = () => {
    return httprequest({
      url: `${properties.api.root}/medicinski-sredstva`,
      method: "get"
    }).then((response) => Promise.resolve(response));
  };

  export const fetch_Details = (id) => {
    return httprequest({
      url: `${properties.api.root}/medicinski-sredstva/findProizvoditeliByIdMedicinsko/${id}`,
      method: "get"
    }).then((response) => Promise.resolve(response));
  };

  export const fetch_Produkti = (id) => {
    return httprequest({
      url: `${properties.api.root}/medicinski-sredstva/findMedicinskiProduktiByIdMedicinsko/${id}`,
      method: "get"
    }).then((response) => Promise.resolve(response));
  };