import actions from "./actions";
import {
  getLekovi,
  fetchItms, 
  fetch_Details,
} from "../../../api/registarLekoviApi";



const fetchItems = (formData) => {
  return (dispatch, getState) => {
    dispatch(actions.fetchRequest());
    return getLekovi()
      .then((resultData) => {
        dispatch(actions.fetchSuccess(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFail(e));
        Promise.reject(e);
      });
  };
};
const fetchDetails = (formData) => {
  return (dispatch, getState) => {
    dispatch(actions.fetchRequest());
    return fetch_Details(formData)
      .then((resultData) => {
        dispatch(actions.fetchSuccess(resultData));
        return Promise.resolve(resultData);
      })
      .catch((e) => {
        dispatch(actions.fetchFail(e));
        Promise.reject(e);
      });
  };
};


const resetReducer = () => {
  return (dispatch, getState) => {
    return dispatch(actions.resetReducer());
  };
};

export default {
  fetchItems,
  resetReducer,
  fetchDetails
};
