import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  AppBar,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { translate } from "./util/lang/translate-wrapper";
import ListIcon from "@material-ui/icons/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faPills,
  faNotesMedical,
  faSyringe,
} from "@fortawesome/free-solid-svg-icons";
import { Link as RouterLink } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    // borderLeft: "1px solid black"
  },
  tab: {
    border: "3px solid white !important",
    color: "white",
    borderRadius: "4px",
  },
  rootTab: {
    padding: "1px",
    fontSize: "13px",
  },
}));

function TabsBar(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/medicinski_sredstva")) setValue(0);
    else if(path.includes("/klinicki_ispituvanja")) setValue(1);
  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <Tabs
          style={{
            backgroundColor: "#912777",
            color: "#d9d9d9",
            marginBottom: "19px",
          }}
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { background: "white", width: "28%", marginLeft: "1%" },
          }}
        >
          {/* <Tab
            style={{ width: "33%", borderLeft: "0.5px solid #b3b3b3" }}
            label={
              <div style={{ display: "inline-block" }}>
                <FontAwesomeIcon
                  style={{ marginRight: "10px" }}
                  icon={faPills}
                  size="lg"
                />{" "}
                {translate("app.register.registarLekovi")}
              </div>
            }
            {...a11yProps(0)}
            classes={{
              selected: classes.tab,
              root: classes.rootTab,
            }}
            component={RouterLink}
            to={"/lekovi"}
          /> */}
          <Tab
            style={{
              width: "33%",
              maxWidth: "100%",
              borderRight: value === 0 ? "0.5px solid #b3b3b3" : "",
              borderLeft: value === 2 ? "0.5px solid #b3b3b3" : "",
            }}
            label={
              <div style={{ display: "inline-block" }}>
                <FontAwesomeIcon
                  style={{ marginRight: "10px" }}
                  icon={faSyringe}
                  size="lg"
                />{" "}
                {translate("app.register.registarMedicinskiSredstva")}{" "}
              </div>
            }
            {...a11yProps(1)}
            classes={{
              selected: classes.tab,
              root: classes.rootTab,
            }}
            component={RouterLink}
            to={"/medicinski_sredstva"}
          />
          <Tab
            style={{
              width: "34%",
              maxWidth: "100%",
            }}
            label={
              <div style={{ display: "inline-block" }}>
                <FontAwesomeIcon
                  style={{ marginRight: "10px" }}
                  icon={faNotesMedical}
                  size="lg"
                />{" "}
                {translate("app.register.registarKlinickiIspituvanja")}{" "}
              </div>
            }
            {...a11yProps(2)}
            classes={{
              selected: classes.tab,
              root: classes.rootTab,
            }}
            component={RouterLink}
            to={"/klinicki_ispituvanja"}
          />
        </Tabs>
      </div>
    </>
  );
}

export default TabsBar;
